import { Avatar, CircularProgress } from '@mui/material';
import { useState } from 'react';
import * as S from '../styles';

type Provider = {
  name: string;
  lowercase_name: string;
};

type SocialAuthScreenActionButtonsProps = {
  provider: Provider;
  onClick: (provider: string) => void;
  disabledButtons: boolean;
  setDisabledButtons: (value: boolean) => void;
};

const SocialAuthScreenActionButtons: React.FC<
  SocialAuthScreenActionButtonsProps
> = ({
  provider,
  onClick,
  disabledButtons,
  setDisabledButtons
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <S.ProviderItem
      onClick={() => {
        setDisabledButtons(true);
        setLoading(true);
        onClick(provider.lowercase_name);
      }}
      aria-label="provider.name"
      key={provider.name}
      sx={{
        borderRadius: '100px'
      }}
      disabled={disabledButtons}
    >
      <S.RoundDiv>
        <Avatar
          alt="Logo da rede social"
          src={`/icons/${provider.lowercase_name}.svg`}
          sx={{ width: 20, height: 20 }}
        />
      </S.RoundDiv>

      <S.ProviderName>
        {loading ? (
          <CircularProgress color="inherit" size={24} />
        ) : (
          provider.name
        )}
      </S.ProviderName>

      <div style={{ width: '3.6rem' }} />
    </S.ProviderItem>
  );
};

export default SocialAuthScreenActionButtons;
