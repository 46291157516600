import { Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import { useState } from 'react';
import { setCookie } from 'nookies';

import CopyrightBox from '@source/components/CopyrightBox';
import { useAuthentication } from '@source/contexts/AuthenticationContext';
import SocialAuthScreenActionButtons from './SocialAuthScreenActionButtons';
import * as S from './styles';

type Provider = {
  name: string;
  lowercase_name: string;
};

const providers: Array<Provider> = [
  { name: 'Google', lowercase_name: 'google' },
  { name: 'Facebook', lowercase_name: 'facebook' },
  { name: 'Apple', lowercase_name: 'apple' },
  { name: 'Email', lowercase_name: 'email' }
];

const SocialAuthScreen: React.FC = (): JSX.Element => {
  const { setRedirectUrl } = useAuthentication();
  const router = useRouter();
  const [disabledButtons, setDisabledButtons] = useState(false);

  // considera todos query params como parte do redirect,
  // se utilizar router.query.redirect, parametros extras não serão passados no redirect
  // ex: riderize.com?param1=test&param2&test) seria redirecionado como riderize.com?param1=test
  const redirect = router.asPath.split('redirect=')[1];

  if (redirect) {
    setRedirectUrl(redirect as string);
    setCookie(undefined, 'riderize@redirect', redirect as string, {
      maxAge: 60 * 60,
      path: '/'
    });
  }

  const onClick = (provider: string) => {
    if (provider === 'email') return router.push('/signin');
    signIn(provider);
  };

  return (
    <S.SocialAuthContainer>
      <Typography
        component="h1"
        variant="h5"
        sx={{
          fontSize: '2.8rem',
          fontWeight: '700',
          color: '#fff',
          padding: '0 2rem'
        }}
      >
        {redirect ? 'Entre para Continuar' : 'Escolha uma opção para entrar'}
      </Typography>

      <S.ProvidersList>
        {providers.map((provider, index) => (
          <SocialAuthScreenActionButtons
            key={index}
            provider={provider}
            onClick={onClick}
            disabledButtons={disabledButtons}
            setDisabledButtons={setDisabledButtons}
          />
        ))}
      </S.ProvidersList>

      <S.Copyright>
        <CopyrightBox />
      </S.Copyright>
    </S.SocialAuthContainer>
  );
};

export default SocialAuthScreen;
