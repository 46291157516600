import styled from 'styled-components';
import media from 'styled-media-query';
import { Grid } from '@mui/material';

export const Wrapper = styled(Grid)`
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.blue};
`;

export const BoxWrapper = styled(Grid)<{ revert: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.colors.blue};
  order: ${({ revert }) => (revert ? 1 : 2)};

  ${media.greaterThan('large')`
    justify-content: center;
    align-items: flex-start;
    border: none;
  `}
`;

export const Slogan = styled.p`
  font-size: 3.2rem;
  line-height: 3.8rem;
  font-weight: 700;
  color: #fff;
  font-family: 'Neuzeit Grotesk';
`;
