import { GetServerSideProps } from 'next';
import nookies from 'nookies';
import LoginBaseLayout from '@components/LoginBaseLayout';
import SocialAuthScreen from '@components/SocialAuthScreen';
import { decodeURL } from '@source/utils/decodeURL';

const Login: React.FC = (): JSX.Element => {
  return (
    <LoginBaseLayout title="Login">
      <SocialAuthScreen />
    </LoginBaseLayout>
  );
};

export default Login;

export const getServerSideProps: GetServerSideProps = async ctx => {
  const cookies = nookies.get(ctx);

  // considera todos query params como parte do redirect,
  // se utilizar router.query.redirect, parametros extras não serão passados no redirect
  // ex: riderize.com?param1=test&param2&test) seria redirecionado como riderize.com?param1=test
  const redirect = ctx.resolvedUrl.split('redirect=')[1];

  if (!redirect) {
    nookies.destroy(ctx, 'riderize@redirect');
    nookies.destroy(ctx, 'riderize@origin-url');
    nookies.destroy(ctx, 'riderize@challenge');
  }

  if (
    cookies['riderize@session'] &&
    cookies['riderize@accessToken'] &&
    cookies['riderize@refreshToken']
  ) {
    return {
      redirect: {
        permanent: false,
        destination: redirect ? decodeURL(redirect) : '/'
      },
      props: {}
    };
  }

  return {
    props: {}
  };
};
