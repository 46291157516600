import { Grid, Box } from '@mui/material';
import Head from 'next/head';

import * as S from './styles';

type LoginLayoutProps = {
  children: React.ReactNode;
  title?: string;
  revert?: boolean;
};

const LoginBaseLayout = ({
  children,
  title,
  revert = false
}: LoginLayoutProps): JSX.Element => {
  return (
    <>
      <Head>
        <title>{`${title} | Riderize - Conectados pelo Ciclismo`}</title>
        <meta name="robots" content="noindex,nofollow" />
      </Head>

      <S.Wrapper container>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            order: { xs: 1, sm: revert ? 2 : 1 }
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${process.env.NEXT_PUBLIC_CDN_URL}/assets/web/img/front-page-bike1.webp)`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: { xs: '50%', sm: '60%' },
              backgroundPosition: 'center',
              height: { xs: '30vh', sm: '60vh' },
              width: '100%'
            }}
          />

          <Grid
            sx={{
              display: { xs: 'none', sm: 'block' },
              width: '100%',
              textAlign: 'center',
              marginTop: '1rem'
            }}
          >
            <Grid>
              <S.Slogan>Conectados pelo Ciclismo</S.Slogan>
            </Grid>
          </Grid>
        </Grid>

        <S.BoxWrapper item xs={12} md={5} revert={revert}>
          {children}
        </S.BoxWrapper>
      </S.Wrapper>
    </>
  );
};

export default LoginBaseLayout;
