import { Typography, Box } from '@mui/material';
import Image from 'next/legacy/image';

const CopyrightBox: React.FC = (): JSX.Element => {
  return (
    <Box
      sx={{
        width: '100%',
        display: { xs: 'flex' },
        justifyContent: { xs: 'space-between', md: 'flex-start' }
      }}
    >
      <Typography
        sx={{ fontSize: '1.4rem', color: '#fff', order: { xs: 1, sm: 2 } }}
      >
        &#169; Riderize
        <br />
        Todos os direitos reservados
      </Typography>

      <Box
        sx={{
          height: '4.8rem',
          width: '4.8rem',
          background: '#045AE5',
          display: 'grid',
          placeItems: 'center',
          order: { xs: 2, sm: 1 },
          marginRight: { md: '2.4rem' }
        }}
      >
        <Image
          src="/icons/riderize-R.svg"
          alt="Ícone Riderize"
          width={24}
          height={22}
        />
      </Box>
    </Box>
  );
};

export default CopyrightBox;
