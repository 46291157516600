import styled from 'styled-components';
import media from 'styled-media-query';
import { IconButton } from '@mui/material';

export const SocialAuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 0;
`;

export const ProvidersList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 2rem;

  ${media.greaterThan('small')`
    width: auto;
    margin-top: 1rem;
  `}
`;

export const ProviderItem = styled(IconButton)`
  width: 100%;
  max-width: 38.4rem;
  margin: 0.8rem auto;
  height: 4.8rem;
  background: #478dff;
  color: #fff;

  display: flex;
  justify-content: space-between;

  &.Mui-disabled {
    opacity: 0.5;
    color: #fff;
  }

  ${media.greaterThan('small')`
    width: 38.4rem;
  `}
`;

export const Copyright = styled.div`
  width: 100%;
  max-width: 38.4rem;
  padding: 1rem 2rem;
  margin: 0.8rem auto;
  height: 4.8rem;
  display: flex;
`;

export const RoundDiv = styled.div`
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProviderName = styled.span`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
`;
